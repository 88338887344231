
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@styles/preflight.css';
import '@styles/global.scss';
import '@styles/tailwind.css';
import '@egjs/flicking/dist/flicking.css';
import 'react-image-hotspot/lib/index.css';
import React from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import Flex from '@react-css/flex';
import useTranslation from 'next-translate/useTranslation';
import i18n from '../../i18n.json';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ConfigProvider, Spin, theme } from 'antd';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useRouter } from 'next/router';
import { apolloClientFactory as adminApolloClientFactory } from '@graphql/admin/apollo/apolloClientFactory';
import { apolloClientFactory as userApolloClientFactory } from '@graphql/user/apollo/apolloClientFactory';
import { LoadingOutlined } from '@ant-design/icons';
import { IntlProvider } from 'react-intl';
import { RecoilRoot } from 'recoil';
import { ErrorBoundary } from '@components/error';
import {
  apolloAdminClientSignal,
  apolloUserClientSignal,
  apolloClientSignal,
} from '@/globals';

type ThenArg<T> = T extends PromiseLike<infer V> ? V : T;

type TApolloClient =
  | ThenArg<ReturnType<typeof adminApolloClientFactory>>
  | ThenArg<ReturnType<typeof userApolloClientFactory>>;

const App: React.FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  const [apolloClient] = apolloClientSignal.use();
  const { lang } = useTranslation();
  const router = useRouter();
  const useAdminClient =
    router.pathname.split('/').filter(Boolean)?.[0]?.toLowerCase() === 'admin';
  const apolloClientPromises = React.useMemo(() => {
    return Promise.all([
      adminApolloClientFactory(lang),
      userApolloClientFactory(lang),
    ]);
  }, [lang]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  React.useEffect(() => {
    apolloClientPromises.then(([adminClient, userClient]) => {
      const client = useAdminClient ? adminClient : userClient;
      apolloAdminClientSignal.update(adminClient);
      apolloUserClientSignal.update(userClient);
      apolloClientSignal.update(client);
    });
  }, [useAdminClient, apolloClientPromises]);

  React.useEffect(() => {
    moment.locale(lang);
    dayjs.locale(lang);
  }, [lang]);

  React.useEffect(() => {
    const handleStart = () => NProgress.start();
    const handleDone = () => NProgress.done();

    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleDone);
    Router.events.on('routeChangeError', handleDone);

    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleDone);
      Router.events.off('routeChangeError', handleDone);
    };
  }, [Router]);

  if (apolloClient) {
    return (
      <ErrorBoundary>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#011951',
              colorLink: '#011951',
            },
            components: {
              Menu: {
                colorItemBgSelected: '#d6dae4',
              },
              Calendar: {
                controlItemBgActive: '#d6dae4',
              },
              Layout: {
                colorBgHeader: '#ffffff',
              },
            },
            algorithm: [theme.defaultAlgorithm],
          }}
        >
          <RecoilRoot>
            <IntlProvider
              locale={
                router.locale ?? router.defaultLocale ?? i18n.defaultLocale
              }
              defaultLocale={router.defaultLocale}
            >
              <ApolloProvider client={apolloClient}>
                <Component {...pageProps} key={router.asPath} />
              </ApolloProvider>
            </IntlProvider>
          </RecoilRoot>
        </ConfigProvider>
      </ErrorBoundary>
    );
  }

  return (
    <Flex
      justifyCenter
      alignItemsCenter
      style={{ height: '100%', margin: 'auto' }}
    >
      <Spin indicator={antIcon} />
    </Flex>
  );
};

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  