export enum AppErrorCodes {
  TENANT_NOT_FOUND = 'tenant_not_found',
  WORKSPACE_NOT_FOUND = 'workspace_not_found',
  CATEGORY_NOT_FOUND = 'category_not_found',
  SUPPLYOBJECT_NOT_FOUND = 'supplyobject_not_found',
  LOCATION_NOT_FOUND = 'location_not_found',
  QUESTION_NOT_FOUND = 'question_not_found',
  CONTEXT_NOT_FOUND = 'context_not_found',
  EVALUATION_NOT_FOUND = 'evaluation_not_found',
  SESSION_NOT_FOUND = 'session_not_found',
  COMPANY_NOT_FOUND = 'company_not_found',
  PARTICIPANT_NOT_FOUND = 'participant_not_found',
  SCHEDULED_SESSION_NOT_FOUND = 'scheduled_session_not_found',
  SCHEDULED_SESSION_PARTICIPANT_ENTRY_NOT_FOUND = 'scheduled_session_participant_entry_not_found',
  SCHEDULED_SESSION_PARTICIPANT_CORRECTION_NOT_FOUND = 'scheduled_session_participant_correction_not_found',
  SESSION_REPORT_NOT_FOUND = 'session_report_not_found',
  OBJECT_REFERENCED = 'object_referenced',
  REALTIME_AUTH_FAILED = 'realtime_auth_failed',
  AUTH_REQUEST_FAILED = 'auth_request_failed',
  AUTH_CODE_INVALID_OR_EXPIRED = 'auth_code_invalid_or_expired',
  USER_CREATION_FAILED = 'user_creation_failed',
  USER_NOT_FOUND = 'user_not_found',
  INVALID_CATEGORY_PARENT = 'invalid_category_parent',
}