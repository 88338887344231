import introspection from '../../../../admin.graphql.schema.json';
import { createUploadLink } from 'apollo-upload-client';
import { relayStylePagination } from '@apollo/client/utilities';
import { ApolloClientHeaders } from './ApolloClientHeaders';
import { ApolloClient as ApolloClientFactory, ApolloLink, InMemoryCache, PossibleTypesMap } from '@apollo/client';

const possibleTypes: PossibleTypesMap = {};
introspection.__schema.types.forEach(supertype => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name);
  }
});

export const apolloClientFactory = (lang: string) => Promise.resolve(new ApolloClientFactory({
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          allUsers: relayStylePagination(),
          allCategories: relayStylePagination(['parent', 'filter', 'order', 'orderBy']),
          allSupplyObjects: relayStylePagination(),
          allLocations: relayStylePagination(),
          allQuestions: relayStylePagination(['filter', 'order', 'orderBy']),
          allContexts: relayStylePagination(),
          allEvaluations: relayStylePagination(['filter', 'order', 'orderBy']),
          allSessions: relayStylePagination(['filter', 'order', 'orderBy']),
          allScheduledSessions: relayStylePagination(['filter', 'order', 'orderBy']),
          allScheduledSessionsBetween: relayStylePagination(['start', 'end']),
          allCompletedScheduledSessions: relayStylePagination(['filter', 'order', 'orderBy']),
          allSessionReports: relayStylePagination(['filter', 'order', 'orderBy']),
          allParticipants: relayStylePagination(['filter', 'order', 'orderBy']),
        }
      }
    }
  }),
  defaultOptions: {
    query: { fetchPolicy: 'network-only' },
    watchQuery: { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' },
  },
  link: createUploadLink({
    uri: process.env.ADMIN_API_URL || 'https://preproduction-admin.api.breezeapp.live/graphql',
    headers: {
      ...ApolloClientHeaders,
      ['accept-language']: lang,
    },
    credentials: 'include',
  }) as unknown as ApolloLink
}));
