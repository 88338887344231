import React, { ErrorInfo } from 'react'
import ErrorView from 'next/error';
import Bugsnag, { Plugin, Client } from '@bugsnag/js';
import pkg from '../../../../package.json';

type State = {
  error: Error | null
  info: ErrorInfo | null
}

type Props = {
  children: React.ReactElement<any>
}

const formatComponentStack = (str: string) => {
  const lines = str.split(/\s*\n\s*/g)
  let ret = ''
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`
  }
  return ret
}

export class ErrorBoundary extends React.Component<{}, State> {
  public static bugsnagClient: Client | null = null
  public static bugsnagPlugin: Plugin = {
    name: 'bugsnag/react',
    load: (client) => {
      ErrorBoundary.bugsnagClient = client;
    },
  }

  public state: State = {
    error: null,
    info: null,
  }

  public handleClearError = () => {
    this.setState({
      error: null,
      info: null,
    });
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    if (ErrorBoundary.bugsnagClient) {
      const client = ErrorBoundary.bugsnagClient;
      const handledState = { severity: 'error', unhandled: true, severityReason: { type: 'unhandledException' } }
      const event = client.Event.create(error, true, handledState, process.env.SANITY_PROJECT_ID, 1)
      if (info && info.componentStack) {
        info.componentStack = formatComponentStack(info.componentStack);
      }
      event.addMetadata('react', info);
      client._notify(event);
    }
    this.setState({ error, info })
  }

  public componentDidMount() {
    if (process.env.BUGSNAG_KEY) {
      Bugsnag.start({
        collectUserIp: false,
        apiKey: process.env.BUGSNAG_KEY,
        appVersion: pkg.version,
        releaseStage: process.env.SANITY_DATASET,
        plugins: [ErrorBoundary.bugsnagPlugin],
      })
    }
  }

  public render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return <ErrorView statusCode={500} />;
    }

    return children;
  }
}
