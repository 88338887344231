export const AppPages = {
  HOME: '/',
  LEGAL: {
    PRIVACY_POLICY: '/legal/privacy-policy',
    COOKIE_POLICY: '/legal/cookie-policy',
  },
  USER: {
    HOME: '/user',
    SIGNUP(code: string = '[code]') {
      return `/user/signup/${code}`;
    },
    SESSION_REPORT(id: string = '[id]') {
      return `/sessionreport/${id}`;
    },
  },
  ADMIN: {
    HOME: '/admin',
    USERMANAGEMENT: '/admin/usermanagement',
    ORGANIZATION: {
      SETTINGS: '/admin/organization/settings'
    },
    SCHEDULED_SESSIONS: {
      ROOT: '/admin/scheduledsessions',
      PLAN: '/admin/scheduledsessions/plan',
      DASHBOARD: {
        ROOT(id = '[sessionId]') {
          return `/admin/scheduledsessions/dashboard/${id}`;
        },
        PARTICIPANT(sessionId = '[sessionId]', participantId = '[participantId]') {
          return `/admin/scheduledsessions/dashboard/${sessionId}/participant/${participantId}`;
        },
      },
    },
    REPORTS: {
      ROOT: '/admin/reports',
      COMPLETED_SESSIONS: {
        ROOT: '/admin/reports/completedsessions',
      },
      SESSIONREPORTS: {
        ROOT: '/admin/reports/sessionreports',
      },
    },
    ASSESSMENTS: {
      ROOT: '/admin/assessments',
      QUESTIONS: {
        ROOT: '/admin/assessments/questions',
        CREATE: '/admin/assessments/questions/create',
        COPY(id: string = '[question]') {
          return `/admin/assessments/questions/create/${id}`;
        },
        EDIT(id: string = '[question]') {
          return `/admin/assessments/questions/edit/${id}`;
        },
      },
      EVALUATIONS: {
        ROOT: '/admin/assessments/evaluations',
        CREATE: '/admin/assessments/evaluations/create',
      },
      SESSIONS: {
        ROOT: '/admin/assessments/sessions',
      },
    },
    DATABASES: {
      ROOT: '/admin/databases',
      CATEGORIES: {
        ROOT: '/admin/databases/categories',
        LEVEL(...parents: string[]) {
          const base = '/admin/databases/categories';
          if (parents.length === 0) { return `${base}/[...parents]`; };
          return `${base}/${parents.join('/')}`;
        }
      },
      SUPPLIES: '/admin/databases/supplies',
      LOCATIONS: '/admin/databases/locations',
      PARTICIPANTS: '/admin/databases/participants',
      COMPANIES: '/admin/databases/companies',
      CONTEXTS: '/admin/databases/contexts',
    }
  },
  API: {
    USER: {
      GRAPHQL: '/api/user/graphql',
      AUTH: {
        CALLBACK: '/api/user/auth/callback'
      },
    },
    ADMIN: {
      GRAPHQL: '/api/admin/graphql',
    },
    AUTH: {
      CALLBACK: '/api/auth/callback',
    },
    PDF: {
      GENERATE: {
        SESSION_REPORT: '/api/pdf/generate/session-report'
      },
    },
    ASSETS: {
      FILE: {
        UPLOAD: {
          FROM_FILE: '/api/assets/file/upload/file',
        },
      },
      IMAGE: {
        UPLOAD: {
          FROM_FILE: '/api/assets/image/upload/file',
          FROM_URL: '/api/assets/image/upload/url'
        }
      }
    }
  },
};