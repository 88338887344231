import introspection from '../../../../admin.graphql.schema.json';
import { InMemoryCache, PossibleTypesMap } from '@apollo/client';

const possibleTypes: PossibleTypesMap = {};
introspection.__schema.types.forEach(supertype => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name);
  }
});

export const ApolloCacheInstance = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
      }
    }
  }
});