import _ from 'lodash';
import nookies from 'nookies';
import hash from 'object-hash';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClientHeaders } from './ApolloClientHeaders';
import { ApolloClient as ApolloClientFactory, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { apolloCachePersistorFactory } from './apolloCachePersistorFactory';
import { ApolloCacheInstance } from './ApolloCacheInstance';
import { getCookie } from 'chunk-cookie';
import { AppCookies } from '@constants/app';

export const apolloClientFactory = (lang: string): Promise<ApolloClientFactory<NormalizedCacheObject>> => new Promise<ApolloClientFactory<NormalizedCacheObject>>(async (resolve, reject) => {
  if (typeof window !== 'undefined') {
    const cookies = nookies.get();
    const authState = getCookie(cookies, AppCookies.USER_AUTH_STATE);
    const authStateHash = authState ? hash(authState) : undefined;
    const persistor = apolloCachePersistorFactory(`apollo:${authStateHash}`);
    try {
      await Promise.race([
        persistor.restore(),
        new Promise(res => setTimeout(res, 10000)),
      ]);
    } catch (err) {
      console.error(err);
    }
  }
  resolve(new ApolloClientFactory({
    cache: ApolloCacheInstance,
    defaultOptions: {
    },
    link: createUploadLink({
      uri: process.env.USER_API_URL || 'https://preproduction-user.api.breezeapp.live/graphql',
      headers: {
        ...ApolloClientHeaders,
        ['accept-language']: lang,
      },
      credentials: 'include',
    }) as unknown as ApolloLink
  }));
});