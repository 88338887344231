import { Answer, QuestionStepType } from '@graphql/admin/generated/graphql.types';
import type { Rule } from 'antd/lib/form';
import type { Translate } from 'next-translate';

type RulesPerType = Partial<Record<QuestionStepType, Rule[]>>
type Options = {
  name: string
  t: Translate | null
}

export const answerValidationRulesFactory = ({ t, name }: Options): RulesPerType => ({
  [QuestionStepType.Practical]: [
    {
      validator: (rule, value?: Answer | null) => {
        if (!value || !value.numericlistValue?.length) return Promise.reject(t?.('validation:required', { field: name }));
        // the total of the checklist positive weights has to get to 100%
        // see issue #187 - https://gitlab.com/breezeapp/breeze-web/-/issues/187
        const total = value.numericlistValue.reduce<number>((sum, weight) => (
          sum + Math.max(0, weight)
        ), 0);
        if (total < 100) {
          return Promise.reject(t?.('validation:answer.practical.insufficient-weight-total', { field: name }));
        }
        return Promise.resolve();
      }
    }
  ]
})
