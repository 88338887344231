import localforage from 'localforage';
import { CachePersistor, LocalStorageWrapper, LocalForageWrapper } from 'apollo3-cache-persist';
import { ApolloCacheInstance } from './ApolloCacheInstance';

const mockLocalStorageWrapper = new LocalStorageWrapper({
  length: 0,
  clear() {},
  key(index: number) { return null; },
  getItem(key: string) { return null; },
  setItem(key: string, value: string) {},
  removeItem(key: string) {},
});

export const apolloCachePersistorFactory = (ns = 'default') => {
  const storageInstance = localforage.createInstance({ name: ns });
  return new CachePersistor({
    cache: ApolloCacheInstance,
    storage: typeof window !== 'undefined' ? new LocalForageWrapper(storageInstance) : mockLocalStorageWrapper,
  });
}